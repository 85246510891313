import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCard from '../../../components/app/Cards/ActionCard';
import UserCard from '../../../components/app/Cards/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Vehicles = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Veículos' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`vehicle/create`} canAddCustomFields={true} title='Veículo' type={controller.general.enums.itemTypes.VEHICLE} item={controller.vehicleCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`vehicle/update/${controller.currentItem._id}`} canAddCustomFields={true} type={controller.general.enums.itemTypes.VEHICLE} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Veículos' />
                {controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                    <S.operationsArea>
                        <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                            <ActionCard mainIcon='faCar' pageItem='Veículo'>
                                Adicionar <br /> veículo
                            </ActionCard>
                        </div>
                        {(controller.vehicles ?? []).map((item: any, index) => {
                            return (
                                <div className='content-cards-wrapper' onClick={() => {
                                    const itemsFilled = [...controller.vehicleCreateTemplate.infos]
                                    itemsFilled.forEach((itemFilled) => {
                                        itemFilled.value = item[itemFilled.key]
                                        if (itemFilled.type === 'money') itemFilled.value = controller.general.formatMoney((itemFilled.value ?? '').toString())
                                    })
                                    item.infos.map((info: any) => {
                                        if (itemsFilled.filter((itemFilled: any) => itemFilled.key == info.key).length == 0) {
                                            itemsFilled.push(info)
                                        }
                                    })
                                    controller.setCurrentItem({ ...item, infos: itemsFilled })
                                    controller.setEditModalActive(active => !active)
                                }} key={`vehicles_${index}`}>
                                    <UserCard title={item.infos?.filter((info: any) => info.key == 'name')[0]?.value ?? 'Não informado'} subtitle={item.createdAt.split('T')[0].split('-').reverse().join('/')} infos={item.infos} />
                                </div>
                            )
                        })}
                    </S.operationsArea>
                )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Vehicles;