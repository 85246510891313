import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import Avatar from 'react-avatar';
import { colors } from '../../../utils/colors';
import { defaultCreateModalProps } from '../../../utils/types';
import general from '../../../utils/general';
import InputText from '../Inputs/Text';
import InputSelect from '../Inputs/Select';
import DateTime from '../Inputs/DateTime';
import Checkbox from '../Inputs/Checkbox';
import SelectWithSearch from '../Inputs/SelectWithSearch';

const DefaultCreateModal = (props: defaultCreateModalProps) => {
    const controller = useController(props);
    return (
        <S.main active={controller.modalShow} animationOpacity={controller.modalShow} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal onClick={(e) => e.stopPropagation()} animationInOut={controller.modalShow}>
                <S.containerForm showItem={controller.optionShowing} onSubmit={(e) => {
                    e.preventDefault();
                    controller.sendRequest(e);
                }}>
                    <S.title>Adicionar {props.title.toLowerCase()}</S.title>
                    <S.allFormInputs ref={controller.inputsListRef} id='inputsFormListCreate'>
                        {controller.item.infos ? controller.item.infos.map((field: typeof controller.item.infos[0], index: number) => {
                            switch (field.type) {
                                case 'text':
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'password':
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} type={'password'} hidden={field.hidden ?? false} />
                                case 'select':
                                    return <InputSelect setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'selectWithSearch':
                                    return <SelectWithSearch defaultValue={field.value?.toString() ?? ''} placeholder={field.placeholder ?? 'Pesquisar...'} isGeneralField={field?.isGeneralField ?? false} width={field?.width ?? '100%'} transparent={field?.transparent ?? false} options={field.selectOptions} executeOnSelect={controller.selectWithSearch}/>
                                case 'datetime':
                                    return <DateTime setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'date':
                                    return <DateTime setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                case 'checkbox':
                                    return <Checkbox setItem={controller.setItem} item={controller.item} field={field} key={controller.general.randomString(10)} randomName={controller.general.randomString(10)} hidden={field.hidden ?? false} />
                                default:
                                    return <InputText setItem={controller.setItem} item={controller.item} field={field} key={index} randomName={controller.general.randomString(10)} type={field.type ?? 'text'} hidden={field.hidden ?? false} />
                            }
                        }) : null}
                    </S.allFormInputs>
                    {props.canAddCustomFields == true ? <S.button onClick={(e) => {
                        e.preventDefault();
                        controller.addNewItemField()
                    }}>Adicionar campo</S.button> : null}
                    <S.button>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={5} size={15}/>
                        Salvar
                    </S.button>
                </S.containerForm>
            </S.modal>
        </S.main>
    );
}

export default DefaultCreateModal;