import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const modalPopInAnimation = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1200px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: auto 1fr;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;

export const modalBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;
export const modalArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 500px;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    padding: 0 20px;
    animation: ${modalPopInAnimation} 0.3s;
`;
export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ${colors.grey};
`;
export const modalTitle = styled.h3`
    font-size: 24px;
    font-weight: 800;
    font-family: ${fonts.main};
`;
export const modalClose = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 30px;
    gap: 10px;
    color: ${colors.grey};
`;
export const closeIcon = styled(FontAwesomeIcon)`
    font-size: 25px;
    color: ${colors.darkGrey};
    cursor: pointer;
    transition-duration: 0.1s;

    &:hover {
        color: ${colors.pureRed};
    }
`;
export const deleteTeamIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    color: ${colors.darkGrey};
    cursor: pointer;
    margin: 0 5px 0 0;
    transition-duration: 0.1s;

    &:hover {
        color: ${colors.pureRed};
    }
`;
export const modalContent = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;

    .modalContentAreaFlexAll {
        flex: 1;
    }
`;
export const modalContentArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
`;
export const modalAddCollaborator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 99999;
`;
export const modalAddCollaboratorArea = styled.form`
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 20px;
    height: auto;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    padding: 20px;
    animation: ${modalPopInAnimation} 0.3s;
`;
export const modalContentTitle = styled.h3`
    font-size: 18px;
    font-weight: 800;
    font-family: ${fonts.main};
    margin-bottom: 10px;
`;
export const saveButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: ${colors.green};
    color: ${colors.backgroundWhite};
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        background-color: ${colors.darkGreen};
    }
`;
export const collaboratorsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 200px;
    margin-top: 10px;
    overflow-y: auto;
    flex: 1;
`;
export const collaborator = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(99% - 20px);
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin-top: 5px;
`;
export const collaboratorInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`;
export const collaboratorName = styled.h4`
    font-size: 16px;
    font-weight: 600;
    font-family: ${fonts.main};
`;
export const collaboratorRole = styled.h4`
    font-size: 13px;
    font-weight: 600;
    margin-left: 2px;
    font-family: ${fonts.main};
    color: ${colors.darkerGrey};
`;
export const collaboratorDelete = styled.div`
    cursor: pointer;
    font-size: 16px;
    color: ${colors.red};
`;
export const collaboratorBtns = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
`;
export const collaboratorBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 0 12px 0;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: ${fonts.main};
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    text-transform: uppercase;
    border: 1px solid ${colors.black};

    :nth-child(1) {
        background-color: ${colors.backgroundWhite};
        border-right: none;
        border-radius: 5px 0 0 5px;
    }
    :nth-child(2) {
        background-color: ${colors.backgroundWhite};
        border-radius: 0 5px 5px 0;
    }

    &:hover {
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media (max-width: 700px) {
        font-size: 13px;
    }
`;
export const collaboratorSaveBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 0 12px 0;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: ${fonts.main};
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    text-transform: uppercase;
    border: 1px solid ${colors.black};

    :nth-child(1) {
        background-color: ${colors.backgroundWhite};
        border-right: none;
        border-radius: 5px 0 0 5px;
    }
    :nth-child(2) {
        background-color: ${colors.backgroundWhite};
        border-radius: 0 5px 5px 0;
    }

    &:hover {
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media (max-width: 700px) {
        font-size: 13px;
    }
`;
export const addCollaboratorArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
`;
export const textInput = styled.input`
    width: calc(100% - 10px);
    height: 30px;
    padding: 5px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin-top: 5px;
    outline: none;

    &:focus {
        transform: scale(1.01);
    }
`;
export const noCollaborators = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
`;
export const noCollaboratorsIcon = styled(FontAwesomeIcon)`
    font-size: 50px;
    color: ${colors.darkGrey};
`;
export const deleteIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    color: ${colors.darkGrey};
    cursor: pointer;

    &:hover {
        color: ${colors.pureRed};
    }
`;
export const noCollaboratorsText = styled.h3`
    font-size: 18px;
    font-weight: 800;
    width: 50%;
    text-align: center;
    font-family: ${fonts.main};
    color: ${colors.darkGrey};
    margin-top: 10px;
`;
export const deleteTeamConfirmationModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
`;
export const deleteTeamConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 150px;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    padding: 20px;
    animation: ${modalPopInAnimation} 0.3s;
`;
export const deleteTeamBtns = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;
export const deleteTeamBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 0 12px 0;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: ${fonts.main};
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    text-transform: uppercase;
    border: 1px solid ${colors.black};

    :nth-child(1) {
        background-color: ${colors.backgroundWhite};
        border-right: none;
        border-radius: 5px 0 0 5px;
    }
    :nth-child(2) {
        background-color: ${colors.backgroundWhite};
        border-radius: 0 5px 5px 0;
    }

    &:hover {
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }
`;