import './nfcupom.css';
import general from '../../../../utils/general';

export const NfcupomTemplate = (props: {
    company_name: string;
    company_document: string;
    emitter: string;
    current_date: string;
    client_name: string;
    client_document: string;
    items: string;
    sub_total_value: string;
    service_fee: string;
    discount: string;
    total_value: string;
}) => {
    return (
        <body>
            <div>
                <table className="printer-ticket">
                    <thead>
                        <tr>
                            <th className="title" colSpan={3}>
                                {props.company_name} <br />
                                <span style={{
                                    fontSize: '0.6em',
                                    fontWeight: 'normal',
                                    textTransform: 'none',
                                }}>{props.company_document}</span>
                            </th> 
                        </tr>
                        <tr>
                            <th colSpan={3}>
                                {props.current_date} < br/>
                                Emissor: {props.emitter}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}>
                                Dados do cliente: <br />
                                {props.client_name} <br />
                                {props.client_document}
                            </th>
                        </tr>
                        <tr>
                            <th className="ttu" colSpan={3}>
                                <b>Cupom não fiscal</b>
                            </th>
                        </tr>
                    </thead>
                    <tbody dangerouslySetInnerHTML={{ __html: props.items }} />
                    <tfoot>
                        <tr className="sup ttu p--0">
                            <td colSpan={3}>
                                <b>Totais</b>
                            </td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Sub-total</td>
                            <td align="right">R${props.sub_total_value}</td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Taxa de serviço</td>
                            <td align="right">{props.service_fee}</td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Desconto</td>
                            <td align="right">{props.discount}</td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Total</td>
                            <td align="right">R${props.total_value}</td>
                        </tr>
                        <tr className="sup">
                            <td colSpan={3} align="center">
                                Emitido com Arezdot ERP <br />
                                https://www.arezdot.com
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </body>
    )
}

export default NfcupomTemplate;