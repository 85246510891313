import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import CreateFinancialMovementModal from '../../../components/app/CreateFinancialMovementModal';
import UpdateFinancialMovementModal from '../../../components/app/UpdateFinancialMovementModal';
import Pagination from '../../../components/general/Pagination';

const Finances = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Finanças' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.updateCompleteModalActive ? <UpdateFinancialMovementModal url={`/financial-movement/update/${controller.currentItem._id}`} executeOnUpdate={controller.getFinancialMovements} setModalActive={controller.setUpdateCompleteModalActive} modalActive={controller.updateCompleteModalActive} item={controller.currentItem} /> : null}
            {controller.createCompleteModalActive ? <CreateFinancialMovementModal executeOnCreate={controller.getFinancialMovements} setModalActive={controller.setCreateCompleteModalActive} modalActive={controller.createCompleteModalActive} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Finanças' />
                {controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                    <S.financesArea>
                        <S.operationsArea>
                            <controller.general.DefaultLoadingFit active={controller.loading} />
                            <S.operationsHeaderArea>
                                <S.operationsSelectLabel htmlFor='order_by'>Ordenar por:</S.operationsSelectLabel>
                                <S.operationSelect id="order_by" name='order_by'>
                                    <S.operationSelectOption value='1'>Mais recentes</S.operationSelectOption>
                                    <S.operationSelectOption value='2'>Mais antigos</S.operationSelectOption>
                                    <S.operationSelectOption value='3'>Maior valor</S.operationSelectOption>
                                    <S.operationSelectOption value='4'>Menor valor</S.operationSelectOption>
                                </S.operationSelect>
                            </S.operationsHeaderArea>
                            <S.operationsList>
                                {(controller.financialMovements ?? []).length > 0 ?
                                    (controller.financialMovements ?? []).map((item: any, index) => {
                                        return (
                                            <S.operation key={`operation_${index}`} onClick={() => {
                                                const itemsFilled = [...controller.financialMovementCreateTemplate.infos]
                                                itemsFilled.forEach((itemFilled) => {
                                                    itemFilled.value = item[itemFilled.key]
                                                    if (itemFilled.type === 'money') itemFilled.value = controller.general.formatMoney((itemFilled.value ?? '').toString())
                                                })
                                                item.infos?.map((info: any) => {
                                                    if (itemsFilled.filter((itemFilled: any) => itemFilled.key == info.key).length == 0) {
                                                        itemsFilled.push(info)
                                                    }
                                                })
                                                controller.setCurrentItem({ ...item, infos: itemsFilled })
                                                controller.setUpdateCompleteModalActive(prev => !prev)
                                            }}>
                                                <S.operationAvatar src={item.type == 1 ? `${process.env.REACT_APP_BASE_URL}app/images/moneyIn.jpg` : `${process.env.REACT_APP_BASE_URL}app/images/moneyOut.jpg`} alt="Imagem para representar a transação financeira" />
                                                <S.operationInfoBits>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Tipo:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>{item.type == 1 ? "Entrada" : "Saída"}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Título:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>{item.title ?? 'Não informado'}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Registrado por:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>{item.account[0].name.split(' ')[0]} {item.account[0].name.split(' ').length > 1 ? item.account[0].name.split(' ')[item.account[0].name.split(' ').length - 1] : ''}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Valor:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>R${controller.general.formatMoney(item.total_value.toString())}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Data prevista:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>{item.due_at ? item.due_at.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                    <S.operationInfoBit>
                                                        <S.operationInfoBitTitle>Pago em:</S.operationInfoBitTitle>
                                                        <S.operationInfoBitValue>{item.paid_at ? item.paid_at.split('T')[0].split('-').reverse().join('/') : 'Aguardando'}</S.operationInfoBitValue>
                                                    </S.operationInfoBit>
                                                </S.operationInfoBits>
                                            </S.operation>
                                        )
                                    })
                                    :
                                    <S.noOperations>
                                        <S.noOperationsIcon icon={controller.general.icons.faExclamationCircle} />
                                        <S.noOperationsText>Nenhuma movimentação financeira encontrada</S.noOperationsText>
                                    </S.noOperations>
                                }
                                {controller.totalPages > 1 && <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} />}
                            </S.operationsList>
                        </S.operationsArea>
                        <S.optionsArea>
                            <S.operationButtons>
                                <S.createOperationBtn onClick={() => controller.setCreateCompleteModalActive(prev => !prev)}>Criar movimentação</S.createOperationBtn>
                            </S.operationButtons>
                            <S.balanceArea>
                                <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={10} />
                                <S.balanceValue balanceType={1} title='Entradas'>
                                    <S.balanceIcon icon={controller.general.icons.faArrowUp} />
                                    R$ {controller.general.formatMoney(controller.totalMoneyIn.toString())}
                                </S.balanceValue>
                                <S.balanceValue balanceType={0} title='Saídas'>
                                    <S.balanceIcon icon={controller.general.icons.faArrowDown} />
                                    R$ {controller.general.formatMoney(controller.totalMoneyOut.toString())}
                                </S.balanceValue>
                            </S.balanceArea>
                            <S.filterArea>
                                <S.filterAreaTitle>Filtrar por:</S.filterAreaTitle>

                                <S.dateContainers>
                                    <S.filterDateContainer>
                                        <S.dateTitle htmlFor='start_date'>Início:</S.dateTitle>
                                        <S.dateInput id='start_date' name='start_date' type='date' value={controller.filters.startDate} onChange={(e) => controller.setFilters({ ...controller.filters, startDate: e.target.value })} />
                                    </S.filterDateContainer>
                                    <S.filterDateContainer>
                                        <S.dateTitle htmlFor='end_date'>Fim:</S.dateTitle>
                                        <S.dateInput id='end_date' name='end_date' type='date' value={controller.filters.endDate} onChange={(e) => controller.setFilters({ ...controller.filters, endDate: e.target.value })} />
                                    </S.filterDateContainer>
                                </S.dateContainers>

                                <S.checkOptionsArea>
                                    <S.checkContainer onClick={() => controller.setFilters({ ...controller.filters, moneyIn: !controller.filters.moneyIn })}>
                                        <S.checkbox>
                                            {controller.filters.moneyIn && <S.checkboxIcon icon={controller.general.icons.faCheck} />}
                                        </S.checkbox>
                                        <S.checkTitle>Entrada</S.checkTitle>
                                    </S.checkContainer>
                                    <S.checkContainer onClick={() => controller.setFilters({ ...controller.filters, moneyOut: !controller.filters.moneyOut })}>
                                        <S.checkbox>
                                            {controller.filters.moneyOut && <S.checkboxIcon icon={controller.general.icons.faCheck} />}
                                        </S.checkbox>
                                        <S.checkTitle>Saída</S.checkTitle>
                                    </S.checkContainer>
                                </S.checkOptionsArea>
                            </S.filterArea>
                        </S.optionsArea>
                    </S.financesArea>
                )}
            </S.generalArea>
        </S.main>
    );
}

export default Finances;