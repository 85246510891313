import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const modalPopInAnimation = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1200px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: auto 1fr;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;
export const modal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;
export const modalContent = styled.form`
    display: flex;
    flex-direction: column;
    width: 400px;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    animation: ${modalPopInAnimation} 0.2s;
`;
export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid ${colors.grey};
`;
export const modalTitle = styled.h3`
    font-family: ${fonts.main};
    font-size: 20px;
    font-weight: 800;
`;
export const modalHeaderIcons = styled.div`
    display: flex;
    gap: 15px;
`;
export const modalClose = styled(FontAwesomeIcon)`
    font-size: 21px;
    cursor: pointer;
    transition-duration: 0.1s;

    &:hover {
        color: ${colors.pureRed};
    }
`;
export const deleteGoal = styled(FontAwesomeIcon)`
    font-size: 16px;
    cursor: pointer;
    transition-duration: 0.1s;
    margin-top: 2px;

    &:hover {
        color: ${colors.pureRed};
    }
`;
export const modalBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;
export const modalInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;
export const modalLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 16px;
    margin-bottom: 5px;
`;
export const modalInputText = styled.input`
    font-family: ${fonts.main};
    font-size: 16px;
    width: calc(100% - 10px);
    height: 30px;
    padding: 5px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    outline: none;
`;
export const modalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    gap: 10px;
`;
export const modalButton = styled.button`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid ${colors.black};
    color: ${colors.black};
    cursor: pointer;

    &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;
export const modalDeleteGoal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
`;
export const deleteConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    animation: ${modalPopInAnimation} 0.2s;
`;
export const deleteConfirmationModalTitle = styled.h3`
    font-family: ${fonts.main};
    font-size: 20px;
    font-weight: 800;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid ${colors.grey};
`;
export const deleteConfirmationModalBtns = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    ${modalButton} {
        :first-child {
            border-radius: 5px 0 0 5px;
            border-right: none;
        }
        :last-child {
            border-radius: 0 5px 5px 0;
        }
    }
`;